<template>
  <div style="width: 65%; justify-content: center; margin: 0 auto;">
    <md-card>
      <md-card-header>
        <div class="md-layout md-size-90 md-alignment-center">
          <p class="md-headline">{{ $t('press.pressPageTitle') }}</p>
        </div>
      </md-card-header>
    </md-card>
  </div>
</template>

<script>
export default {
  name: 'Press',
};
</script>
